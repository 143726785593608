import { Observable } from 'rxjs'

export default class SuggesterService {
  getObservableChannel(type) {
    return new Observable(subscriber => {
      this.channel = window.App.cable.subscriptions.create(
        { channel: 'SuggestionsChannel', type: type },
        {
          received: data => {
            if (data) {
              subscriber.next({
                suggestions: data
              })
            }
          }
        }
      )
    })
  }
  requestSuggestions(params) {
    this.channel.send({ suggest: params, locale: document.querySelector('html').lang })
  }
  deleteSubscription() {
    window.App.cable.subscriptions.remove(this.channel)
  }
}
