function updateTimeago() {
  var timeTags = document.querySelectorAll('time');
  Array.prototype.forEach.call(timeTags, function(timeTag, i) {
    datetime = Date.parse(timeTag.getAttribute('data-datetime'));
    now = new Date();
    difference = (now - datetime) / 1000;
    var timeString = '';

    if (isNaN(datetime)) {
      var label = timeTag.getAttribute('data-limit-label');
      if (label) {
        timeString = timeTag.getAttribute('data-limit-label');
      } else {
        timeString = timeTag.getAttribute('title');
      }
    } else if (difference < 2) {
      timeString = timeago.x_seconds_ago
                          .one;
    } else if (difference < 60) {
      timeString = timeago.x_seconds_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference)));
    } else if (difference < 2 * 60) {
      timeString = timeago.x_minutes_ago
                          .one;
    } else if (difference < 60 * 60) {
      timeString = timeago.x_minutes_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference / 60)));
    } else if (difference < 2 * 60 * 60) {
      timeString = timeago.x_hours_ago
                          .one;
    } else if (difference < 24 * 60 * 60) {
      timeString = timeago.x_hours_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference / (60 * 60))));
    } else if (difference < 2 * 24 * 60 * 60) {
      timeString = timeago.x_days_ago
                          .one;
    } else if (difference < 28 * 24 * 60 * 60) {
      timeString = timeago.x_days_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference / (24 * 60 * 60))));
    } else if (difference < 2 * 28 * 24 * 60 * 60) {
      timeString = timeago.x_months_ago
                          .one;
    } else if (difference < 365 * 24 * 60 * 60) {
      timeString = timeago.x_months_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference / (28 * 24 * 60 * 60))));
    } else if (difference < 2 * 365 * 24 * 60 * 60) {
      timeString = timeago.x_years_ago
                          .one;
    } else {
      timeString = timeago.x_years_ago
                          .other
                          .replace('x', parseInt(Math.floor(difference / (365 * 24 * 60 * 60))));
    }
    timeTag.innerHTML = timeString;
    timeTag.dataset.title = timeString;
  });
}
updateTimeago();
setInterval(updateTimeago, 1000);
