export default function browserDetect() {
  const options = {
    osVersion: true,
    minorBrowserVersion: true
  }

  var browserData = {
    browsers: {
      chrome: uaMatch(/Chrome\/([0-9\.]*)/),
      firefox: uaMatch(/Firefox\/([0-9\.]*)/),
      safari: uaMatch(/Version\/([0-9\.]*).*Safari/),
      opera: uaMatch(/Opera\/.*Version\/([0-9\.]*)/, /Opera\/([0-9\.]*)/),
      msie: uaMatch(/MSIE ([0-9\.]*)/, /Trident.*rv:([0-9\.]*)/)
    },
    engines: {
      webkit: uaContains('AppleWebKit'),
      trident: uaMatch(/(MSIE|Trident)/),
      gecko: uaContains('Gecko'),
      presto: uaContains('Presto')
    },
    platforms: {
      win: uaMatch(/Windows NT ([0-9\.]*)/),
      mac: uaMatch(/Mac OS X ([0-9_\.]*)/),
      linux: uaContains('X11', 'Linux')
    }
  }

  var ua = navigator.userAgent
  var detectData = {
    platform: detectItem(browserData.platforms),
    browser: detectItem(browserData.browsers),
    engine: detectItem(browserData.engines)
  }

  function uaMatch(regExp, altReg) {
    return function() {
      var result = regExp.exec(ua) || altReg && altReg.exec(ua)
      return result && result[1]
    }
  }

  function uaContains(word) {
    var args = Array.prototype.slice.apply(arguments)
    return function() {
      for (var i = 0; i < args.length; i++) {
        if (ua.indexOf(args[i]) < 0) {
          return
        }
      }
      return true
    }
  }

  function detectItem(items) {
    var detectedItem = null, itemName, detectValue
    for (itemName in items) {
      if (items.hasOwnProperty(itemName)) {
        detectValue = items[itemName]()
        if (detectValue) {
          return {
            name: itemName,
            value: detectValue
          }
        }
      }
    }
  }

  (function () {
    var addClass = function (cls) {
      var html = document.documentElement
      html.className += (html.className ? ' ' : '') + cls
    }
    var getVersion = function (ver) {
      return typeof ver === 'string' ? ver.replace(/\./g, '_') : 'unknown'
    }
    if (detectData.platform) {
      addClass(detectData.platform.name)
      if (options.osVersion) {
        addClass(detectData.platform.name + '-' + getVersion(detectData.platform.value))
      }
    }
    if (detectData.engine) {
      addClass(detectData.engine.name)
    }
    if (detectData.browser) {
      addClass(detectData.browser.name)
      addClass(detectData.browser.name + '-' + parseInt(detectData.browser.value, 10))
      if (options.minorBrowserVersion) {
        addClass(detectData.browser.name + '-' + getVersion(detectData.browser.value))
      }
    }
  }())

  return detectData
}
