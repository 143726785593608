import { marked } from 'marked'
import DOMPurify from 'dompurify'

marked.setOptions({ breaks: true })

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank')
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute('target') &&
    (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new')
  }
})

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))
export const truncateString = (str) => {
  let num = 27
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num).replace(/\([^)]+$/, '') + '...'
}
export const COLORS = {
  primary_color: '#0077b6',
  primary_color_50: '#DFEEF6',
  primary_color_100: '#cce4f0',
  primary_color_400: '#3392c5',
  primary_color_500: '#0077B6',
  grey_color_100: '#f0f1f2',
  grey_color_200: '#c4c8cb',
  grey_color_300: '#a7acb1',
  grey_color_500: '#6c757d',
  grey_color_600: '#565e64',
  grey_color_700: '#41464b',
  grey_color_800: '#2b2f32',
  white_color: '#fff',
  cta_color_100: '#fad7da',
  cta_color_200: '#f5afb5',
  cta_color_300: '#ef888f',
  cta_color_400: '#ea606a'
}
export const FONTS = {
  roboto_sans_serif: 'Roboto, sans-serif'
}
export const buildFilterParams = (filters, user_access) => {
  let events = user_access === 'plus' ? '&fulltext[sources][events][since]=now-3M&fulltext[sources][events][until]=now' : ''
  return [
    Array.from(filters.fulltexts).map(fulltext => `fulltext[queries][]=${encodeURIComponent(fulltext)}&fulltext[sources][websites]=true&fulltext[sources][companies]=true${events}`).sort(),
    Array.from(filters.industries).map(industry => `industry[]=${industry}`).sort(),
    Array.from(filters.locations).map(location => `location[]=${location}`).sort(),
    Array.from(filters.sizes).map(size => `size[]=${size}`).sort(),
  ].flat().join('&')
}
export const buildSearchFilterParams = (filters) => {
  return [
    Array.from(filters.fulltexts || []).map(fulltext => `fulltext[queries][]=${encodeURIComponent(fulltext)}`).sort(),
    buildSearchOptions(filters),
    Array.from(filters.industries || []).map(industry => `industry[]=${industry}`).sort(),
    Array.from(filters.not_industries || []).map(not_industry => `not_industry[]=${not_industry}`).sort(),
    Array.from(filters.locations || []).map(location => `location[]=${location}`).sort(),
    Array.from(filters.not_locations || []).map(not_location => `not_location[]=${not_location}`).sort(),
    Array.from(filters.sizes || []).map(size => `size[]=${size}`).sort(),
    Array.from(filters.employees || []).map(employee => `employees=${employee}`).sort(),
    Array.from(filters.revenue || []).map(revenue => `revenue=${revenue}`).sort(),
    Array.from(filters.founding_year || []).map(founding_year => `founding_year=${founding_year}`).sort(),
    Array.from(filters.available_data || []).map(data => `available_data[]=${data}`).sort(),
    Array.from(filters.all_legal_forms || []).map(data => `all_legal_forms=${data}`).sort(),
    Array.from(filters.inactive_companies || []).map(data => `inactive_companies=${data}`).sort()
  ].flat().join('&')
}
const buildSearchOptions = (filters) => {
  if (filters.fulltexts && filters.fulltexts.size > 0) {
    let optionsURL = []
    if (filters.fulltext_options?.websites || filters.fulltext_options?.events) {
      for (const [key, value] of Object.entries(filters.fulltext_options)) {
        if (key === 'companies') {
          optionsURL.push(`fulltext[sources][companies]=${JSON.parse(value)}`)
        }
        if (key === 'websites') {
          optionsURL.push(`fulltext[sources][websites]=${JSON.parse(value)}`)
        }
        if (key === 'events') {
          optionsURL.push(`fulltext[sources][events][since]=${value.since}`)
          optionsURL.push('fulltext[sources][events][until]=now')
        }
      }
    } else {
      optionsURL.push('fulltext[sources][companies]=true')
      optionsURL.push('fulltext[sources][websites]=true')
      optionsURL.push('fulltext[sources][events][since]=now-3M')
      optionsURL.push('fulltext[sources][events][until]=now')
    }
    return optionsURL
  } else {
    return []
  }
}
export const containSameArrayElements = (array1, array2) => {
  let sortedArray2 = array2.slice().sort()
  let sortedArray1 = array1.slice().sort()
  if (sortedArray1.length === sortedArray2.length && sortedArray1.every((value, index) => value === sortedArray2[index])) {
    return true
  } else {
    return false
  }
}
export const typeWriter = (element) => {
  element.classList.remove('hide')
  return new Promise((resolve) => {
    let char = 0
    function helper() {
      if (char < element.querySelector('p').dataset.text.length) {
        element.querySelector('p').innerHTML += element.querySelector('p').dataset.text.charAt(char)
        char++
        setTimeout(helper, 50)
      } else {
        resolve()
      }
    }
    helper()
  })
}