(() => {
  document.addEventListener('turbolinks:load', () => {
    document.querySelectorAll('.jump-to-search').forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()
        document.querySelector('#suggester')?.classList.remove('hide')
        document.querySelector('.search-btn').click()
      })
    })
  })
})()
