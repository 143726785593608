(() => {
  document.addEventListener('turbolinks:load', function () {
    document.querySelectorAll('.removeBlur').forEach(function (blur) {
      blur.addEventListener('click', function () {
        this.classList.add('hide')
        document.querySelector('.warnMessage').classList.remove('hide')
        setTimeout(function () {
          document.querySelectorAll('.blurContact').forEach(function (contact) {
            contact.classList.remove('blurContact')
            contact.classList.remove('hide')
          })
        }, 3000)
      })
    })
  })
})()
