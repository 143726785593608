export default function modalTriggerCustom() {
  document.querySelectorAll('.modal-trigger-custom').forEach(function (modalTrigger) {
    modalTrigger.addEventListener('click', function (e) {
      e.preventDefault()
      let attribute = this.attributes['data-target'] ? this.attributes['data-target'] : this.attributes['href']
      let login_location = modalTrigger.getAttribute('href')
      if (attribute) {
        let modal = document.querySelector(attribute.value)
        window.M.Modal.init(modal,
          {
            onCloseStart: function () {
              document.body.style = ''
            },
            onOpenEnd: function () {
              document.querySelectorAll('.tabs').forEach((tabsInstance) => {
                if (login_location === '#login') {
                  tabsInstance.querySelector('#login-tab')?.classList.add('active')
                  tabsInstance.querySelector('#registration-tab')?.classList.remove('active')
                }
                if (login_location === '#register') {
                  tabsInstance.querySelector('#registration-tab')?.classList.add('active')
                  tabsInstance.querySelector('#login-tab')?.classList.remove('active')
                }
                window.M.Tabs.init(tabsInstance)?.updateTabIndicator()
              })
            }
          }
        )
        var modalInstance = window.M.Modal.getInstance(modal)
      }
      modalInstance?.open()
      if (modalInstance?.$el[0]?.querySelector('textarea')) {
        modalInstance?.$el[0]?.querySelector('textarea')?.focus()
      }

    })
  })
}