(() => {
  document.addEventListener('turbolinks:load', () => {
    const button = document.getElementById('scroll-to-service')
    button?.addEventListener('click', () => {
      const element = document.getElementById('b2b-service-title')
      const offset = 70
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    })
  })
})()