<template>
  <div>
    <a
      class="modal-trigger-custom"
      :class="button_classes.join(' ')"
      :href="`#${modal_id}`"
    >
      <i
        v-show="button_with_icon"
        :data-icon="icon_name"
        class="material-icons tiny"
        :class="{'m-0': button_only_icon}"
      />
      <span
        v-show="!button_only_icon"
      >
        {{ button_name }}
      </span>
    </a>
    <div
      :id="modal_id"
      :class="modal_classes.join(' ')"
      class="modal"
    >
      <div class="modal-header">
        <div class="h4">
          <i
            class="material-icons left icon-border"
            :data-icon="icon_name"
          />
          <span>
            {{ modal_name }}
          </span>
        </div>
        <a
          class="modal-close btn btn-small grey right hide-on-small-only"
        >
          <i
            class="material-icons left"
            data-icon="close"
          />
          <span>
            {{ close_button_name }}
          </span>
        </a>
        <a
          class="modal-close btn-small btn-floating right grey hide-on-med-and-up"
        >
          <i
            class="material-icons left"
            data-icon="close"
          />
        </a>
      </div>
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import modalTriggerCustom from '../snippets/modalTriggerCustom'
export default {
  props: {
    modal_id: {
      type: String,
      default: 'vue_modal'
    },
    modal_classes: {
      type: Array,
      default: () => ['modal']
    },
    button_classes: {
      type: Array,
      default: () => ['btn', 'btn-small', 'grey']
    },
    button_with_icon: {
      type: Boolean,
      default: false
    },
    button_only_icon: {
      type: Boolean,
      default: false
    },
    icon_name: {
      type: String,
      default: 'add'
    },
    modal_name: {
      type: String,
      default: 'Modal'
    },
    button_name: {
      type: String,
      default: 'Modal'
    },
    close_button_name: {
      type: String,
      default: 'Close'
    }
  },
  mounted() {
    this.modalTriggerCustom()
  },
  methods: {
    modalTriggerCustom
  }
}
</script>
