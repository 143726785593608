<template>
  <div>
    <div
      id="suggester"
      v-click-outside="handleOutsideClick"
      :class="{'hide' : controller == 'homepage'}"
      @click="handleSuggesterClick"
    >
      <a
        v-if="show_overlay_board"
        class="back-button"
        @click="handleOutsideClick"
      >
        <i
          class="material-icons"
          data-icon="arrow_back"
        />
      </a>
      <div
        v-if="show_overlay_board"
        class="separation-line"
      />
      <form>
        <input
          ref="search-input"
          v-model="search_query"
          autocomplete="off"
          class="search-bar hide-on-small-only"
          type="text"
          :placeholder="locales.placeholder"
          @focus="handleOnFocus"
          @input="handleInput"
          @keydown.enter.prevent="handleOnEnter(search_query)"
          @keydown.esc="handleOutsideClick"
        >
      </form>
      <preloader-mini v-show="loading && show_overlay_board && search_query" />
      <a
        v-show="search_query && show_overlay_board"
        class="delete-button"
        @click="handleOnDelete"
      >
        <i
          class="material-icons"
          data-icon="close"
        />
      </a>
      <div
        v-show="search_query && show_overlay_board"
        class="separation-line"
      />
      <a
        class="search-btn"
        @click="handleOnClickSearch"
      >
        <i
          class="material-icons"
          data-icon="search"
        />
      </a>
    </div>
    <transition name="fade">
      <overlay-board
        v-show="show_overlay_board"
        :locale="locale"
        :fetch_url="fetch_url"
        :search_query="search_query"
        :suggestions="suggestions"
        :locales="locales"
        :marketing_img_src="marketing_img_src"
        :saved_searches="saved_searches ? JSON.parse(saved_searches) : []"
        :saved_searches_url="saved_searches_url"
        @handleOnEnter="handleOnEnter"
        @addFilterToFilters="addFilterToFilters"
        @submitRequest="submitRequest"
      />
    </transition>
  </div>
</template>
<script>
import { buildFilterParams } from '../../helpers'
import SuggestionsService from '../suggestions_service'
const suggesterService = new SuggestionsService()

export default {
  name: 'Suggester',
  components: {
    OverlayBoard: () => import('./overlay_board.vue'),
    PreloaderMini: () => import('../preloader_mini.vue')
  },
  props: {
    fetch_url: {
      type: String,
      default: ''
    },
    initial_locales: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: ''
    },
    input_placeholder: {
      type: String,
      default: ''
    },
    company_title: {
      type: String,
      default: ''
    },
    marketing_img_src: {
      type: String,
      default: ''
    },
    controller: {
      type: String,
      default: ''
    },
    user_access: {
      type: String,
      default: ''
    },
    saved_searches: {
      type: String,
      default: ''
    },
    saved_searches_url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      suggestions_timer: null,
      locales: JSON.parse(this.initial_locales),
      show_overlay_board: false,
      search_query: '',
      suggestions: {
        companies: []
      },
      filters: {
        fulltexts: new Set(),
        industries: new Set(),
        locations: new Set(),
        sizes: new Set()
      }
    }
  },
  beforeMount() {
    suggesterService.getObservableChannel('suggestions')
      .subscribe((data) => {
        if (this.search_query === data.suggestions.query) {
          this.loading = false
          this.suggestions = data.suggestions
        }
      })
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  updated() {
    const suggester_btn = document.querySelector('.btn.suggester-btn.jump-to-search')
    if (this.show_overlay_board) {
      document.body.classList.add('overflow-hidden')
      if (suggester_btn) {
        window.suggester_btn_observer.unobserve(suggester_btn)
      }
    } else {
      document.body.classList.remove('overflow-hidden')
      if (suggester_btn) {
        window.suggester_btn_observer.observe(suggester_btn)
      }
    }
  },
  beforeDestroy() {
    suggesterService.deleteSubscription()
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleInput(event) {
      this.search_query = event.target.value
      this.show_overlay_board = true
      this.requestSuggestions()
    },
    requestSuggestions() {
      clearTimeout(this.suggestions_timer)
      this.loading = false
      if (this.search_query !== '') {
        this.loading = true
        this.suggestions_timer = setTimeout(() => {
          suggesterService.requestSuggestions({
            query: this.search_query,
            companies: 7,
            locations: 5,
            industries: 5,
            sizes: 4
          })
        }, 300)
      }
    },
    handleResize(){
      if (window.innerWidth > 600) {
        this.handleOutsideClick()
      }
    },
    handleSuggesterClick() {
      this.$refs['search-input']?.focus()
    },
    handleOnFocus() {
      this.show_overlay_board = true
      if (window.innerWidth <= 600) {
        document.querySelector('.right-nav-element')?.classList.add('hide')
        document.querySelector('.left-nav-element')?.classList.add('hide')
      }
    },
    handleOutsideClick() {
      this.show_overlay_board = false
      if (window.innerWidth <= 600) {
        this.$refs['search-input']?.classList.add('hide-on-small-only')
      }
      document.querySelector('.right-nav-element')?.classList.remove('hide')
      document.querySelector('.left-nav-element')?.classList.remove('hide')
    },
    handleOnDelete() {
      this.search_query = ''
      this.$refs['search-input']?.focus()
    },
    addFilterToFilters(filter) {
      switch (filter.category) {
      case 'fulltext':
        this.filters.fulltexts.add(filter.query)
        break
      case 'industry':
        this.filters.industries.add(`nace:${filter.id}`)
        break
      case 'location':
        this.filters.locations.add(`${filter.type}:${filter.id}`)
        break
      case 'size':
        this.filters.sizes.add(filter.id)
        break
      }
    },
    handleOnEnter(inputParams) {
      if (inputParams) {
        this.setCookie()
        this.addFilterToFilters({
          category: 'fulltext',
          query: inputParams
        })
        this.submitRequest()
      }
    },
    submitRequest() {
      let url = `${this.fetch_url}/${this.locale}/search?${buildFilterParams(this.filters, this.user_access)}`
      window.Turbolinks.visit(url)
    },
    handleOnClickSearch() {
      if (this.search_query == '' || this.show_overlay_board == false ) {
        this.$refs['search-input']?.classList.remove('hide-on-small-only')
        this.$refs['search-input']?.focus()
      } else {
        this.handleOnEnter(this.search_query)
      }
    },
    setCookie() {
      let cookie = window.getCookie('latest-search')
      let expires = new Date()
      expires.setDate(expires.getDate() + 1)
      let cookies = []
      if (cookie) {
        cookies = JSON.parse(cookie)
        if (this.search_query && !cookies.includes(this.search_query) && cookies != ''){
          cookies.unshift(this.search_query)
          cookies = cookies.slice(0, 5)
        }
      } else {
        cookies = [this.search_query]
      }
      window.setCookie('latest-search', JSON.stringify(cookies), expires)
    }
  }
}
</script>
