
export default function intersectionObserver(tag, component) {
  const options = { rootMargin: '200px' }
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)
        return component()
      }
    })
  }, options)
  observer.observe(tag)
}