(() => {
  document.addEventListener('turbolinks:load', () => {
    document.querySelectorAll('.copy-button').forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault()
        navigator.clipboard.writeText(button.dataset.copyvalue)
        let icon = button.querySelector('i.material-icons')
        icon.dataset.icon = 'check'
        setTimeout(() => {
          icon.dataset.icon = 'content_copy'
        }, 2000)
      })
    })
  })
})()