import * as CryptoJS from 'crypto-js'

document.addEventListener('turbolinks:load', () => {
  const email = document.getElementById('registration-email')?.value
  const auth_token = document.getElementById('auth_token')?.value
  const clg_field = document.getElementById('clg')

  if (email && auth_token && clg_field) {
    const reverse_email = email.split('').reverse().join('')
    const string = `${reverse_email}${auth_token}Implisense123!`
    const hash = CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex)
    clg_field.value = hash
  }
})