export default function leafletCluster() {
  import('leaflet').then(() => {
    import('leaflet.markercluster').then(() => {
      document.querySelectorAll('#clustermap').forEach(function (clustermap) {
        var iconUrl = clustermap.dataset.iconUrl
        var locations = JSON.parse(clustermap.dataset.locations)
        var markers = L.markerClusterGroup()
        var bounds = L.latLngBounds(locations)
        var map = L.map('clustermap', { dragging: true, scrollWheelZoom: true })
        L.icon({
          iconUrl: iconUrl,
          iconSize: [28, 40],
          iconAnchor: [14, 40]
        })
        L.tileLayer('https://tiles.implisense.com/osm-intl/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          maxZoom: 18,
          dragging: 'false',
          scrollWheelZoom: 'false'
        }).addTo(map)
        map.fitBounds(bounds)
        locations.forEach(function (location) {
          var marker = L.marker(new L.LatLng(location[0], location[1]), { icon: L.divIcon({ html: '1', className: 'marker-cluster-single', iconSize: L.point(40, 40) }) })
          markers.addLayer(marker)
        })
        map.addLayer(markers)
      })
    })
  })
}