(() =>  {
  document.addEventListener('turbolinks:load', function () {
    if (!document.querySelector('.subscriptions.new')) {
      return;
    }
    document.querySelectorAll('.radio-button').forEach(function (button) {
      button.addEventListener('click', function () {
        setPaymentMethodVisiblity();
      })
    })
    var setPaymentMethodVisiblity = function () {
      var creditCardForm = document.getElementById('creditcard-form');
      var sepaCardForm = document.getElementById('sepa-form');
      var paypalForm = document.getElementById('paypal-form');
      var radioIbanButton = document.querySelector('.radio-button.iban');
      var radioPaypalButton = document.querySelector('.radio-button.paypal');
      var radioCreditcardButton = document.querySelector('.radio-button.creditcard');

      if (radioIbanButton?.checked) {
        paypalForm?.classList.add('hide');
        creditCardForm?.classList.add('hide');
        sepaCardForm?.classList.remove('hide');
      } else if (radioPaypalButton?.checked) {
        sepaCardForm?.classList.add('hide');
        creditCardForm?.classList.add('hide');
        paypalForm?.classList.remove('hide');
      } else if (radioCreditcardButton?.checked) {
        sepaCardForm?.classList.add('hide');
        paypalForm?.classList.add('hide');
        creditCardForm?.classList.remove('hide');
      }
    }
    setPaymentMethodVisiblity();
  });
})();
