import { typeWriter } from '../helpers'
(() => {
  document.addEventListener('turbolinks:load', () => {
    const firstCard = document.querySelectorAll('#assist-preview .card.reply')[0]
    const secondCard = document.querySelector('#assist-preview .card.question')
    const thirdCard = document.querySelectorAll('#assist-preview .card.reply')[1]
    const allCards = [firstCard, secondCard, thirdCard]
    if (!firstCard || !secondCard || !thirdCard) return

    function hideElementsAndRemoveText() {
      return new Promise((resolve) => {
        allCards.forEach((card) => {
          card.classList.add('hide')
          card.querySelector('p').innerHTML = ''
          setTimeout(() => resolve(), 50)
        })
      })
    }

    function delay(duration) {
      return new Promise((resolve) => {
        setTimeout(resolve, duration)
      })
    }

    function startTyping() {
      hideElementsAndRemoveText()
      typeWriter(firstCard)
      .then(() => delay(1000))
      .then(() => typeWriter(secondCard))
      .then(() => delay(1000))
      .then(() => typeWriter(thirdCard))
      .then(() => delay(5000))
      .then(() => hideElementsAndRemoveText())
      .then(() => startTyping())
    }
    startTyping()
  })
})()