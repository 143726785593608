window.getCookie = function (key) {
  var value;
  document.cookie.split(';').forEach(function (cookie) {
    if (cookie.split('=')[0].trim() == key) {
      value = cookie.split('=')[1].trim()
      return;
    }
  });
  return value;
}

window.setCookie = function (key, value, expireTime) {
  document.cookie = key + '=' + value + '; path=/' + `; expires= ${expireTime}`;
}

window.deleteCookie = function (key) {
  document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

window.deleteCookieFromPrefix = function (prefix) {
  document.cookie.split(';').forEach(function (cookie) {
    var key = cookie.split('=')[0].trim();
    if (key.includes(prefix)) {
      deleteCookie(key);
    }
  });
}
