
export default function leafletHeat() {
  import('leaflet').then(() => {
    import('leaflet.heat').then(() => {
      document.querySelectorAll('#heatmap').forEach(function (heatmap) {
        var map = L.map('heatmap', { dragging: false, scrollWheelZoom: false })
        map.setView([51.163361, 10.447683], 5)
        L.tileLayer('https://tiles.implisense.com/osm-intl/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          maxZoom: 18,
          dragging: 'false',
          scrollWheelZoom: 'false'
        }).addTo(map)
        var heatdata = JSON.parse(heatmap.dataset.heatdata)
        var gradient = JSON.parse(heatmap.dataset.gradient)
        L.heatLayer(
          heatdata,
          {
            gradient: gradient,
            radius: 25.0,
            blur: 25.0,
            max: 1.0,
            minOpacity: 0.6
          }
        ).addTo(map)
      })
    })
  })
}
