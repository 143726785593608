(() => {
  document.addEventListener('turbolinks:load', () => {
    if (document.querySelector('.btn.suggester-btn.jump-to-search')) {
      const suggester_btn = document.querySelector('.btn.suggester-btn.jump-to-search')
      const options = { rootMargin: '65px 0 0 0' }
      window.suggester_btn_observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            document.querySelector('#suggester')?.classList.add('hide')
          } else {
            document.querySelector('#suggester')?.classList.remove('hide')
          }
        }, options)
      })
      window.suggester_btn_observer.observe(suggester_btn)
    }
  })
})()
