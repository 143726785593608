(() => {
  document.addEventListener('turbolinks:load', () => {
    document.querySelectorAll('.api-tokens .switch').forEach((sw) => {
      sw.addEventListener('click', (event) => {
        event.stopPropagation()
        let collap = event.target.closest('.collapsible')
        if (sw.querySelector('input').checked) {
          window.M.Collapsible.getInstance(collap).open(0)
        } else {
          window.M.Collapsible.getInstance(collap).close(0)
        }
      })
    })
  })
})()