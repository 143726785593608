(() => {
  document.addEventListener('turbolinks:load', () => {
    if (window.innerWidth <= 600) {
      const scroll_bar = document.querySelector('.scroll-container')
      if (scroll_bar) {
        scroll_bar.scroll(200, 0)
        setTimeout( () => { scroll_bar.scroll(0, 0) }, 500)
      }
    }
  })
})()