(() => {
  document.addEventListener('turbolinks:load', () => {
    const delete_input = document.querySelector('.confirm-deletion input')
    if (delete_input) {
      delete_input.addEventListener('change', () => {
        const button = document.querySelector('.delete-account-button')
        if (button) {
          button.classList.toggle('disabled')
        }
      })
    }
  })
})()