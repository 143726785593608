export default function leaflet() {
  import('leaflet').then(() => {
    document.querySelectorAll('#map').forEach(function (map) {
      var latitude = map.dataset.latitude
      var longitude = map.dataset.longitude
      var iconUrl = map.dataset.iconUrl

      var showGermany = map.dataset.showGermany
      var dragging = showGermany ? true : false

      var leafletMap = L.map('map', {
        dragging: dragging,
        scrollWheelZoom: false,
        zoomSnap: 0
      })

      if (showGermany) {
        var width = map.offsetWidth
        var zoom = Math.log2(width / 327 * 32)
        leafletMap.setView([51.165691, 10.451526], zoom)
      } else {
        leafletMap.setView([latitude, longitude], 15)
      }

      L.tileLayer('https://tiles.implisense.com/osm-intl/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 18,
        dragging: dragging,
        scrollWheelZoom: 'false',
      }).addTo(leafletMap)
      var icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [28, 40],
        iconAnchor: [14, 40]
      })
      L.marker(
        [latitude, longitude],
        { icon: icon }
      ).addTo(leafletMap)
    })
  })
}
